import Swiper, { Navigation, Pagination } from 'swiper';

$(function() {
  if($('.js-magazine-swiper').length){
    const historySwiper = new Swiper('.js-magazine-swiper', {
      modules: [Navigation, Pagination],
      slidesPerView:1,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        960: {
          slidesPerView:2.4,
        }
      },
      navigation: {
        nextEl: '.swiper-history-next',
        prevEl: '.swiper-history-prev',
      },
    });
    // historySwiper.init();
  }
});
